<script>
import Service from '../core/results-service'

export default {
  name: 'ServiceProvider',

  render() {
    return this.$slots.default
  },

  data: () => ({
    svc: new Service()
  }),

  provide() {
    return {
      svc: this.svc
    }
  }
}
</script>
